export const USER_DETAILS_FORM: UserDetailsFormConfigProps = {
  AGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: '<15', text: 'Under 15' },
    { value: '15-29', text: '15-29' },
    { value: '30-49', text: '30-49' },
    { value: '50-69', text: '50-69' },
    { value: '70+', text: '70+' }
  ],

  GENDER_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' },
    { value: 'other', text: 'Other' },
    { value: 'preferNotToSay', text: 'Prefer not to say' }

  ],

  ACCENT_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'african', text: 'African' },
    { value: 'africanSouth', text: 'South African' },
    { value: 'london', text: 'London' },
    { value: 'yorkshire', text: 'Yorkshire' },
    { value: 'geordie', text: 'Geordie' },
    { value: 'midlands', text: 'Midlands' },
    { value: 'northern', text: 'Northern' },
    { value: 'southern', text: 'Southern' },
    { value: 'irish', text: 'Irish' },
    { value: 'irishNorthern', text: 'Northern Irish' },
    { value: 'scottish', text: 'Scottish' },
    { value: 'welsh', text: 'Welsh' },
    { value: 'german', text: 'German' },
    { value: 'french', text: 'French' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'italian', text: 'Italian' },
    { value: 'european', text: 'European' },
    { value: 'asianSouth', text: 'South Asian (Indian, Pakistani etc)' },
    { value: 'asianEast', text: 'East Asian (China, Japan etc)' },
    { value: 'asianOther', text: 'Asian (Other)' },
    { value: 'australian', text: 'Australian' },
    { value: 'newZealand', text: 'New Zealand' },
    { value: 'americanNorth', text: 'North American' },
    { value: 'americanSouth', text: 'South American' },
    { value: 'other', text: 'Other' }
  ],

  REGION_OPTIONS: [
  ],

  LANGUAGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'english', text: 'English' },
    { value: 'afrikaans', text: 'Afrikaans' },
    { value: 'zulu', text: 'Zulu' },
    { value: 'venda', text: 'Venda' },
    { value: 'swati', text: 'Swati' },
    { value: 'ndebele', text: 'Ndebele' },
    { value: 'tsonga', text: 'Tsonga' },
    { value: 'Xhosa', text: 'Xhosa' },
    { value: 'northern sotho', text: 'Northern Sotho' },
    { value: 'southern sotho', text: 'Southern Sotho' },
    { value: 'tswana', text: 'Tswana' },
    { value: 'other', text: 'Other' },
    { value: 'arabic', text: 'Arabic' },
    { value: 'bengali', text: 'Bengali' },
    { value: 'cantonese', text: 'Cantonese' },
    { value: 'croatian', text: 'Croatian' },
    { value: 'czech', text: 'Czech' },
    { value: 'danish', text: 'Danish' },
    { value: 'dutch', text: 'Dutch' },
    { value: 'filipino', text: 'Filipino' },
    { value: 'finnish', text: 'Finnish' },
    { value: 'french', text: 'French' },
    { value: 'german', text: 'German' },
    { value: 'greek', text: 'Greek' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'italian', text: 'Italian' },
    { value: 'japanese', text: 'Japanese' },
    { value: 'korean', text: 'Korean' },
    { value: 'mandarin', text: 'Mandarin' },
    { value: 'nepali', text: 'Nepali' },
    { value: 'norwegian', text: 'Norwegian' },
    { value: 'polish', text: 'Polish' },
    { value: 'portuguese', text: 'Portuguese' },
    { value: 'punjabi', text: 'Punjabi' },
    { value: 'russian', text: 'Russian' },
    { value: 'serbian', text: 'Serbian' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'swedish', text: 'Swedish' },
    { value: 'turkish', text: 'Turkish' },
    { value: 'vietnamese', text: 'Vietnamese' }

  ],

  SECOND_LANGUAGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'english', text: 'English' },
    { value: 'afrikaans', text: 'Afrikaans' },
    { value: 'zulu', text: 'Zulu' },
    { value: 'venda', text: 'Venda' },
    { value: 'swati', text: 'Swati' },
    { value: 'ndebele', text: 'Ndebele' },
    { value: 'tsonga', text: 'Tsonga' },
    { value: 'Xhosa', text: 'Xhosa' },
    { value: 'northern sotho', text: 'Northern Sotho' },
    { value: 'southern sotho', text: 'Southern Sotho' },
    { value: 'tswana', text: 'Tswana' },
    { value: 'other', text: 'Other' },
    { value: 'arabic', text: 'Arabic' },
    { value: 'bengali', text: 'Bengali' },
    { value: 'cantonese', text: 'Cantonese' },
    { value: 'croatian', text: 'Croatian' },
    { value: 'czech', text: 'Czech' },
    { value: 'danish', text: 'Danish' },
    { value: 'dutch', text: 'Dutch' },
    { value: 'filipino', text: 'Filipino' },
    { value: 'finnish', text: 'Finnish' },
    { value: 'french', text: 'French' },
    { value: 'german', text: 'German' },
    { value: 'greek', text: 'Greek' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'italian', text: 'Italian' },
    { value: 'japanese', text: 'Japanese' },
    { value: 'korean', text: 'Korean' },
    { value: 'mandarin', text: 'Mandarin' },
    { value: 'nepali', text: 'Nepali' },
    { value: 'norwegian', text: 'Norwegian' },
    { value: 'polish', text: 'Polish' },
    { value: 'portuguese', text: 'Portuguese' },
    { value: 'punjabi', text: 'Punjabi' },
    { value: 'russian', text: 'Russian' },
    { value: 'serbian', text: 'Serbian' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'swedish', text: 'Swedish' },
    { value: 'turkish', text: 'Turkish' },
    { value: 'vietnamese', text: 'Vietnamese' }
  ]
}
