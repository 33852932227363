export const TRANSCRIPTS: TranscriptsConfigProps = {
  default: [
    {
      transcript: 'KykNET & Kie HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Arendsvlei',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Binnelanders',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Getroud Met Rugby',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Jy Is My Seun',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'KykNET HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Die Real Housewives Van Die Kaapse ...',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Die Real Housewives Van Die Wynlande',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Diepe Waters',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Dina Dagbreek',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'In Gesprek',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Jan Braai Vir Erfenis',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Lui Maar Op Belinda',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mooiweer En Warm',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mr. Bones 3: Son Of Bones',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'OMPAD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Projek Dina',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Suidooster',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Troukoors',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Valentynssokkie',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: "WIE WORD 'N MILJOENÊR?",
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'KykNET Lekker',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Suidooster',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mzansi Magic HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Achuzi No. 1',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Bala Family, The',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'DATE MY FAMILY',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Gomora',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Gqeberha: The Empire',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'I Blew It',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Iifiso',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Ingane Yam',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Isifiso',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Kings Of Maskandi',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Komkhulu',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Makoti, Are You The One?',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Nganele',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Our Perfect Wedding',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Pop Goes Freedom',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: "S'jola Sonke",
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The Bala Family',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The River',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The Wife',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Umkhokha: The Curse',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Umuntu Wethu',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mzansi Wethu HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Abandoned',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Idols SA',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Izangoma Zodumo',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Lingashoni',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Short And Sweet',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The Queen',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The River',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Ubizo',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Umbuso',
      type: 'Phrase',
      repeats: 1
    }
  ],
  kids: [
    {
      transcript: 'KykNET & Kie HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Arendsvlei',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Binnelanders',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Getroud Met Rugby',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Jy Is My Seun',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'KykNET HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Die Real Housewives Van Die Kaapse ...',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Die Real Housewives Van Die Wynlande',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Diepe Waters',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Dina Dagbreek',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'In Gesprek',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Jan Braai Vir Erfenis',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Lui Maar Op Belinda',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mooiweer En Warm',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mr. Bones 3: Son Of Bones',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'OMPAD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Projek Dina',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Suidooster',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Troukoors',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Valentynssokkie',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: "WIE WORD 'N MILJOENÊR?",
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'KykNET Lekker',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Suidooster',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mzansi Magic HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Achuzi No. 1',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Bala Family, The',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'DATE MY FAMILY',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Gomora',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Gqeberha: The Empire',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'I Blew It',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Iifiso',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Ingane Yam',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Isifiso',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Kings Of Maskandi',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Komkhulu',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Makoti, Are You The One?',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Nganele',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Our Perfect Wedding',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Pop Goes Freedom',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: "S'jola Sonke",
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The Bala Family',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The River',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The Wife',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Umkhokha: The Curse',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Umuntu Wethu',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Mzansi Wethu HD',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Abandoned',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Idols SA',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Izangoma Zodumo',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Lingashoni',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Short And Sweet',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The Queen',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'The River',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Ubizo',
      type: 'Phrase',
      repeats: 1
    },
    {
      transcript: 'Umbuso',
      type: 'Phrase',
      repeats: 1
    }
  ]
}
