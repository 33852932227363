import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Store from '../index'

import AxiosConfig from '@/api/axiosConfig'

import { CONFIG_FILE_PATH } from '@/constants'

@Module({
  dynamic: true,
  store: Store,
  name: 'configuration',
  namespaced: true
})
export default class Configuration extends VuexModule {
  public configBranding = {}
  public configVersion = {}
  public configTitle = ''
  public configRegion = ''
  public configcountryCode = ''
  public configServices = []

  get branding (): Record<string, string> {
    return this.configBranding
  }

  get title (): string {
    return this.configTitle
  }

  get region (): string {
    return this.configRegion
  }

  get countryCode (): string {
    return this.configcountryCode
  }

  get version (): Record<string, string> {
    return this.configVersion
  }

  get endpoint () {
    return (serviceName: string, endpointName: string) => {
      let value = ''

      for (let indexService = 0; indexService < this.configServices.length; indexService++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const serviceObj: any = this.configServices[indexService]

        if (serviceObj.name === serviceName) {
          value = serviceObj.endpoints[endpointName]
          break
        }
      }

      return value
    }
  }

  get fullUrlEndpoint () {
    return (serviceName: string, endpointName: string) => {
      let value = ''

      for (let indexService = 0; indexService < this.configServices.length; indexService++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const serviceObj: any = this.configServices[indexService]

        if (serviceObj.name === serviceName) {
          value = serviceObj.api_url + '/' + serviceObj.endpoints[endpointName]
        }
      }

      return value
    }
  }

  @Mutation
  // eslint-disable-next-line
  public setConfiguration (data: Record<string, any>): void {
    this.configBranding = data.branding
    this.configTitle = data.title
    this.configRegion = data.region
    this.configcountryCode = data.countryCode
    this.configVersion = data.version
    this.configServices = data.services

    AxiosConfig.getInstance().updateInstance(this.configServices)
  }

  @Action({ commit: 'setConfiguration' })
  // eslint-disable-next-line
  public async load (): Promise<Record<string, any> | undefined> {
    try {
      const response = await fetch(CONFIG_FILE_PATH)

      // eslint-disable-next-line
      const data: Record<string, any> = await response.json()

      return data
    } catch (error) {
      console.error(error)
    }
  }
}
