export const TRANSLATIONS: TranslationsConfigProps = {
  headerWebsiteTitle: 'MultiChoice Voice Collection Service',
  progressListWelcome: 'Welcome',
  progressListYourDetails: 'Your Details',
  progressListBackgroundAudioCheck: 'Background Audio Check',
  progressListRecordings: 'Recordings',
  progressListDone: 'Done',
  homeHelloText: 'Hello',
  homeNeedHelpText: 'The Voice Team needs your help!',
  homeThankYouText:
    'Got 5-10 mins to spare and want to help out with an exciting new feature development? We are collecting audio samples from as many different voices as possible.',
  homeSamplesText:
    'We need even more samples - especially from kids under 10. So if you fancy getting involved, it\'s not too late and there are 10 Sky Store Movie Vouchers up for grabs. The deadline for entry is the 18th December.',
  homeSpareTimeText:
    "Have a read of the legal bit below and if you're happy to help us out, click Next.",
  homeNextText: 'Next',
  homeCloseText: 'Close',
  homeContinueText: 'Continue',
  homeTermsAndConditions: 'Terms And Conditions',
  homeRequiredCheckboxes:
    'Checkboxes marked with an asterisk are required!',
  userDetailsTitleText: 'Your Details',
  userDetailsSessionText: [
    '(Note: this data is optional. You can click Next to skip to audio collection)',
    'This session is only for',
    'ONE',
    'person. You\'ll have the chance to record different people after your session.'
  ],
  userDetailsFormLabelsText: [
    'Age Group',
    'Gender',
    'Regional Accent (Closest)',
    'Your Region (Closest)',
    'Native Language',
    'Second Language'
  ],
  userDetailsNextText: 'Next',
  backgroundAudioCheckTitleText: 'Background Audio Check',
  backgroundAudioCheckPermissionForMicText:
    'We are going to need your permission to access your microphone to check the noise levels in your surroundings.',
  backgroundAudioCheckFindQuietSpaceText:
    'Try to find a quiet space for the remainder of your sessions.',
  backgroundAudioCheckHeadsetText:
    'If you have a headset please put that on and make sure it is the selected microphone for your broswer.',
  backgroundAudioCheckCheckText: 'Check background audio levels',
  backgroundAudioCheckAudioGoodText: [
    'You\'re background audio levels are good!',
    'You are ready to begin recording.'
  ],
  backgroundAudioCheckBeginRecordingText: 'Begin Recording',
  backgroundAudioCheckErrorText:
    'Something went wrong, refresh the page and try again. Make sure the Microphone settings are allowed for this portal.',
  recordingsTitleText: 'Recordings',
  recordingsGetReadyText: 'Get ready…',
  recordingsSayWakewordText: 'Say: ',
  recordingsBeginText: 'Start recording',
  recordingsContinueSessionText: 'Continue session',
  recordingsHaveAListenText: 'Have a listen to your recording below',
  recordingsSatisfiedText: 'Are you satisfied with the result?',
  recordingsRedoRecordingText: 'No, redo recording',
  recordingsNextRecordingText: 'Yes, proceed to next recording',
  recordingsFinishText: 'Finish session',
  recordingsSpeechInstructionsText:
    'Say the text above just once. Then wait until the timer runs out.',
  recordingsStayQuietText:
    'Try keep as silent as possible during the countdown!',
  recordingsAreYouStillThereText: 'Hey...Are you still there?',
  recordingsGoneQuietText: 'Seems you\'ve gone quiet',
  recordingsRefreshPromptText:
    'Click the button below to refresh the page and carry on.',
  recordingsRefreshText: 'Refresh Page',
  recordingsReturnHelloText: 'Welcome back!',
  recordingsReturnText:
    'You haven\'t finished your last session. Would you like to continue?',
  recordingsReturnCarryOnText: 'Yes, continue saved session.',
  recordingsReturnRestartText: 'No, start from the beginning.',
  recordingsProgressText: 'Your progress:',
  doneThankYouText: 'Thank you!',
  doneAllDoneText: 'All done!',
  donePrizeText: '',
  doneContactText: '',
  doneAddMoreText: 'Start another session',
  errorBrowserUnsupportedTitle: 'Browser not supported',
  errorBrowserUnsupportedText:
    'Only Chrome, Firefox, Safari and Edge are supported at the moment.',
  toastErrorErrorTitle: 'Error',
  toastErrorRecordingsTitle: 'Recordings',
  toastErrorUserCreate: 'User could not be registered.',
  toastErrorBackgroundNoise: 'Background noise could not be checked.',
  toastErrorMicrophoneAccess:
    'No access to microphone. Please check permissions and try again.',
  toastErrorRecordPartCreate: 'Your recording could not be sent.',
  toastErrorTokenUnauthorised:
    'Access to this feature could not be granted. Please try again, refresh the page or log in again.',
  contactEmail: ''
}
